var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Container } from '../../common/components/UI/Container';
import { useQuery } from '@apollo/client';
import { PRIVACY_POLICY } from '../../graphql/queries/singleTypes/policy';
import { Loader } from '../../common/components/Loader';
import { DataContext } from '../../root/Root';
import Heading from '../../common/components/Heading';
import { generateHTMLFromRichText } from '../../helpers/markdownHelper';
export var Policy = function () {
    var dataContext = useContext(DataContext);
    var navigationExpanded = dataContext.state.navigationExpanded;
    var _a = useQuery(PRIVACY_POLICY), error = _a.error, data = _a.data, loading = _a.loading;
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(_Fragment, {});
    var policy = data === null || data === void 0 ? void 0 : data.privacyPolicy.data.attributes;
    return (_jsx("article", __assign({ onClick: function () {
            return navigationExpanded && dataContext.setState(function (prev) { return (__assign(__assign({}, prev), { navigationExpanded: false })); });
        } }, { children: _jsx(Container, __assign({ id: 'form' }, { children: _jsxs(_Fragment, { children: [_jsx(Heading, __assign({ as: 'h1' }, { children: policy.title })), generateHTMLFromRichText(policy.policy)] }) })) })));
};
