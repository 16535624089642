var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { HashLoader } from 'react-spinners';
import loadingStyles from './style.module.scss';
export var Loader = function (_a) {
    var size = _a.size, fullHeight = _a.fullHeight;
    return (_jsx("div", __assign({ className: fullHeight ? loadingStyles.loadingWrapperFullHeight : loadingStyles.loadingWrapper, style: fullHeight ? { marginLeft: "-".concat(size ? size / 2 : 25, "px") } : {} }, { children: _jsx(HashLoader, { color: "#2d536d", loading: true, size: size ? size : 50, "aria-label": "Loading Spinner", "data-testid": "loader" }) })));
};
