var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import css from './Message.module.scss';
import { closed } from 'react-icons-kit/iconic/closed';
import Heading from '../Heading';
import { Text } from '../Text';
import { Fade } from 'react-awesome-reveal';
import Icon from 'react-icons-kit';
import { ic_error_outline_outline } from 'react-icons-kit/md/ic_error_outline_outline';
var MessageSent = function (_a) {
    var title = _a.title, subheading = _a.subheading, status = _a.status, className = _a.className, direction = _a.direction;
    return (_jsx("div", __assign({ className: "".concat(css.message, " ").concat(className !== null && className !== void 0 ? className : '') }, { children: _jsxs(Fade, __assign({ direction: direction !== null && direction !== void 0 ? direction : 'left', triggerOnce: true, style: { margin: 'auto' } }, { children: [_jsx(Icon, { icon: status === 'success' ? closed : ic_error_outline_outline, className: css.iconSent, size: 64 }), _jsx(Heading, __assign({ as: 'h4' }, { children: title })), _jsx(Text, { children: subheading })] })) })));
};
export default MessageSent;
