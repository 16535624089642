var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Swiper } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import css from './Swiper.module.scss';
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
export var SwiperCar = function (_a) {
    var data = _a.data, children = _a.children, bullets = _a.bullets, breakpoints = _a.breakpoints, className = _a.className;
    var modules = [Navigation];
    if (bullets)
        modules.push(Pagination);
    return (_jsx(Swiper, __assign({ slidesPerView: 1, spaceBetween: 25, breakpoints: breakpoints !== null && breakpoints !== void 0 ? breakpoints : {
            480: {
                slidesPerView: 2,
            },
            767: {
                slidesPerView: 3,
            },
        }, modules: modules, loop: true, loopedSlides: data.length, pagination: bullets
            ? {
                clickable: true,
                bulletClass: "".concat(css.bullet),
                horizontalClass: "".concat(css.bulletsWrapper),
                bulletActiveClass: "".concat(css.activeBullet),
            }
            : {}, className: "".concat(css.swiper, " ").concat(className) }, { children: children })));
};
