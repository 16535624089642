var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from '../../common/components/Heading';
import { useQuery } from '@apollo/client';
import { FOOTER } from '../../graphql/queries/singleTypes/footer';
import css from './Footer.module.scss';
import { getMediaIcon } from '../../helpers/socialMediaHelper';
import Icon from 'react-icons-kit';
import { MarkdownText, Text } from '../../common/components/Text';
import { ErrorMessage } from '../Error/Error';
import Logo from '../../common/components/UIElements/Logo';
export var Footer = function () {
    var _a = useQuery(FOOTER), data = _a.data, loading = _a.loading, error = _a.error;
    if (error)
        return _jsx(ErrorMessage, { title: 'find - Footer', errorMessage: error.message });
    if (loading)
        return _jsx(_Fragment, {});
    var footerData = data === null || data === void 0 ? void 0 : data.footer.data.attributes.Footer;
    return (_jsx("footer", __assign({ className: css.FooterWrapper }, { children: _jsxs("div", __assign({ className: css.ContentWrapper }, { children: [_jsxs("div", __assign({ className: "".concat(css.column, " ").concat(css.burdyCol) }, { children: [_jsx(Logo, { fetchPriority: "low", className: css.logo, withAnchor: true, anchorLink: "/", src: footerData.SocialMedia.Logo.data.attributes.url, alt: footerData.SocialMedia.Logo.data.attributes.alternativeText }), _jsx("ul", __assign({ className: css.SocialList }, { children: footerData.SocialMedia.social_medias.data.map(function (item, i) { return (_jsx("li", { children: _jsx("a", __assign({ "aria-label": item.attributes.Media + 'link', href: item.attributes.Link, target: "_blank", rel: "noreferrer" }, { children: _jsx(Icon, { icon: getMediaIcon(item.attributes.Media.toLowerCase()), size: 30 }) })) }, "link-key".concat(i))); }) })), _jsx(Text, { children: footerData.SocialMedia.Text })] })), _jsxs("div", __assign({ className: "".concat(css.column) }, { children: [_jsx(Heading, __assign({ as: 'h5' }, { children: footerData.Contact.Title })), _jsx(MarkdownText, __assign({ className: css.MarginFooterMarkdown }, { children: footerData.Contact.Text }))] })), _jsxs("div", __assign({ className: "".concat(css.column) }, { children: [_jsx(Heading, __assign({ as: 'h5' }, { children: footerData.Adress.Title })), _jsx(MarkdownText, __assign({ className: css.MarginFooterMarkdown }, { children: footerData.Adress.Text }))] })), footerData.Policies.Text && (_jsxs("div", __assign({ className: "".concat(css.column, " ").concat(css.policyCol) }, { children: [_jsx(Heading, __assign({ as: 'h5' }, { children: footerData.Policies.Title })), _jsx(MarkdownText, __assign({ className: css.MarginFooterMarkdown }, { children: footerData.Policies.Text }))] })))] })) })));
};
