var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import css from './Heading.module.scss';
import { Fade } from 'react-awesome-reveal';
var Heading = function (_a) {
    var children = _a.children, as = _a.as, className = _a.className;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var getClassName = function (as) {
        switch (as) {
            case 'h1':
                return css.h1;
            case 'h2':
                return css.h2;
            case 'h3':
                return css.h3;
            case 'h4':
                return css.h4;
            case 'h5':
                return css.h5;
            default:
                return css.h3;
        }
    };
    if (as === 'h2') {
        return (_jsxs(Fade, __assign({ direction: "up", cascade: true, triggerOnce: true, fraction: 0.5 }, { children: [_jsx("p", __assign({ className: "".concat(getClassName(as), " ").concat(className) }, { children: children })), ' '] })));
    }
    return _jsx("p", __assign({ className: "".concat(getClassName(as), " ").concat(className) }, { children: children }));
};
export default Heading;
