//eslint-disable-next-line @typescript-eslint/no-explicit-any
export var getCustomerData = function (data) {
    var _a, _b, _c;
    var customers = [];
    data.customers.data.map(function (item) {
        var _a, _b, _c, _d;
        customers.push({
            id: item.id,
            title: item.attributes.Title,
            shortProjectDesc: item.attributes.ShortProjectDescription,
            image: {
                src: (_a = item.attributes.Image.data) === null || _a === void 0 ? void 0 : _a.attributes.url,
                alt: (_b = item.attributes.Image.data) === null || _b === void 0 ? void 0 : _b.attributes.alternativeText,
            },
            logo: {
                src: (_c = item.attributes.Logo.data) === null || _c === void 0 ? void 0 : _c.attributes.url,
                alt: (_d = item.attributes.Logo.data) === null || _d === void 0 ? void 0 : _d.attributes.alternativeText,
            },
        });
    });
    return {
        title: (_a = data.customerSection.data) === null || _a === void 0 ? void 0 : _a.attributes.Title,
        subheading: (_b = data.customerSection.data) === null || _b === void 0 ? void 0 : _b.attributes.Subheading,
        text: (_c = data.customerSection.data) === null || _c === void 0 ? void 0 : _c.attributes.Text,
        customers: customers,
    };
};
