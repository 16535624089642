var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import css from './Input.module.scss';
export var Textfield = function (_a) {
    var label = _a.label, type = _a.type, multiline = _a.multiline, required = _a.required, name = _a.name, control = _a.control, className = _a.className, dark = _a.dark;
    return (_jsx(Controller, { name: name, control: control, rules: { required: required !== null && required !== void 0 ? required : false }, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value, error = _a.fieldState.error;
            return (_jsxs("div", __assign({ className: "".concat(css.Textfield, " ").concat(dark ? css.dark : css.light, " ").concat(className) }, { children: [multiline ? (_jsx("textarea", { className: error ? css.error : (value === null || value === void 0 ? void 0 : value.length) > 0 ? css.inputWithValue : null, id: label, cols: 30, rows: 3, onChange: function (v) { return onChange(v); }, value: value !== null && value !== void 0 ? value : '' })) : (_jsx("input", { className: error ? css.error : (value === null || value === void 0 ? void 0 : value.length) > 0 ? css.inputWithValue : null, id: label, type: type, onChange: function (v) { return onChange(v); }, value: value !== null && value !== void 0 ? value : '' })), _jsxs("label", __assign({ htmlFor: label }, { children: [type === 'email' ? 'E-postadress' : label, required && '*'] }))] })));
        } }));
};
