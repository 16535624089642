// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zK9YmJVIALr9kmht8Xwc{display:block;max-width:100%;height:auto}`, "",{"version":3,"sources":["webpack://./src/common/components/Image/Image.module.scss"],"names":[],"mappings":"AAAA,sBACI,aAAA,CACA,cAAA,CACA,WAAA","sourcesContent":[".image {\r\n    display: block;\r\n    max-width: 100%;\r\n    height: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `zK9YmJVIALr9kmht8Xwc`
};
export default ___CSS_LOADER_EXPORT___;
