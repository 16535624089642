var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from '../../common/components/Heading';
import { MarkdownText } from '../../common/components/Text';
import { Container } from '../../common/components/UI/Container';
import { CarouselCircle } from './CarouselCircle';
import css from './Team.module.scss';
import { useQuery } from '@apollo/client';
import { TEAM } from '../../graphql/queries/singleTypes/team';
import { formatTeamData } from './TeamData';
import { ErrorMessage } from '../Error/Error';
export var Team = function () {
    var _a = useQuery(TEAM), data = _a.data, loading = _a.loading, error = _a.error;
    if (error)
        return _jsx(ErrorMessage, { title: 'find - Team', errorMessage: error.message });
    if (loading)
        return _jsx(_Fragment, {});
    var teamData = formatTeamData(data);
    return (_jsxs(Container, __assign({ id: "team" }, { children: [_jsxs("header", __assign({ className: css.header }, { children: [_jsx(Heading, __assign({ as: 'h2' }, { children: teamData.title })), teamData.subHeading && _jsx(Heading, __assign({ as: 'h3' }, { children: teamData.subHeading })), teamData.text && _jsx(MarkdownText, { children: teamData.text })] })), _jsx("div", __assign({ className: css.CarouselWrapper }, { children: teamData.employees.length > 0 && _jsx(CarouselCircle, { employees: teamData.employees }) }))] })));
};
