var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from '../common/components/Heading';
import { LinkTag } from '../common/components/Link';
import { Text } from '../common/components/Text';
import css from '../root/Root.module.scss';
var getHeadingLevel = function (level) {
    switch (level) {
        case 1:
            return 'h1';
        case 2:
            return 'h2';
        case 3:
            return 'h3';
        case 4:
            return 'h4';
        case 5:
        default:
            return 'h5';
    }
};
export var generateHTMLFromRichText = function (data) {
    console.log(data);
    var html = [];
    var richText = data;
    richText.forEach(function (x) {
        var _a;
        switch (x.type) {
            case 'heading':
                var heading = x.children[0];
                html.push(_jsx(Heading, __assign({ as: getHeadingLevel((_a = x.level) !== null && _a !== void 0 ? _a : 5) }, { children: heading.text })));
                break;
            case 'paragraph':
                var paragraphs = x.children;
                if (paragraphs.length === 1) {
                    if (paragraphs[0].type === 'text')
                        html.push(_jsx(Text, __assign({ bold: paragraphs[0].bold, italic: paragraphs[0].italic, strikeThrough: paragraphs[0].strikethrough, underline: paragraphs[0].underline }, { children: paragraphs[0].text }), paragraphs[0].text));
                    else
                        html.push(_jsx(LinkTag, __assign({ className: css.link, to: paragraphs[0].url }, { children: _jsx(Text, __assign({ className: css.link }, { children: " ".concat(paragraphs[0].children[0].text) })) }), paragraphs[0].url));
                }
                else {
                    html.push(_jsx("p", { children: paragraphs.map(function (p) {
                            if (p.type === 'text')
                                return (_jsx(Text, __assign({ bold: p.bold, italic: p.italic, strikeThrough: p.strikethrough, underline: p.underline, inline: true }, { children: p.text }), p.text));
                            else
                                return (_jsx(LinkTag, __assign({ className: css.link, to: p.url }, { children: _jsx(Text, __assign({ inline: true, className: css.link }, { children: "  ".concat(p.children[0].text) })) }), p.url));
                        }) }));
                }
                break;
            case 'list':
                var listElements_1 = [];
                var listNodes = x.children;
                listNodes.forEach(function (n) {
                    var node = n.children;
                    listElements_1.push(_jsx("li", { children: node.map(function (p, i) { return (_jsxs(Text, __assign({ bold: p.bold, italic: p.italic, strikeThrough: p.strikethrough, underline: p.underline, inline: true }, { children: [p.text, ' '] }), i)); }) }));
                });
                x.format === 'unordered' ? html.push(_jsx("ul", { children: listElements_1 })) : html.push(_jsx("ol", { children: listElements_1 }));
        }
    });
    return html;
};
