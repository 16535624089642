var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import css from './Card.module.scss';
import { Image } from '../Image';
export var ImageCard = function (_a) {
    var overlayElement = _a.overlayElement, src = _a.src, alt = _a.alt, className = _a.className, style = _a.style, fetchPriority = _a.fetchPriority;
    return (_jsxs("div", __assign({ className: "".concat(className, " ").concat(css.imageCard), style: style }, { children: [_jsx(Image, { fetchPriority: fetchPriority, className: css.image, src: src, alt: alt }), overlayElement] })));
};
