var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import css from './HamburgMenu.module.scss';
import { DataContext } from '../../../root/Root';
var HamburgMenu = function (_a) {
    var className = _a.className;
    var dataContext = useContext(DataContext);
    var setState = dataContext.setState;
    var navigationExpanded = dataContext.state.navigationExpanded;
    return (_jsxs("button", __assign({ onClick: function () { return setState(function (prev) { return (__assign(__assign({}, prev), { navigationExpanded: !prev.navigationExpanded })); }); }, className: " ".concat(css.HamburgMenuBtn, " ").concat(className, " ").concat(navigationExpanded ? css.active : css.resting), "aria-label": "hamburgMenu" }, { children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] })));
};
export default HamburgMenu;
