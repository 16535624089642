var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import css from './Button.module.scss';
export var PrimaryButton = function (_a) {
    var type = _a.type, text = _a.text, className = _a.className;
    return (_jsx("button", __assign({ className: "".concat(css.primaryBtn, " ").concat(className), type: type }, { children: text })));
};
