var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import css from './Campaign.module.scss';
import { Textfield } from '../../common/components/Input/Textfield';
import { NumberInput } from '../../common/components/Input/NumberInput';
import { PrimaryButton } from '../../common/components/Button/PrimaryButton';
import { CAMPAIGN_REQUEST } from '../../graphql/mutations/campaignRequest';
import { useMutation } from '@apollo/client';
import MessageSent from '../../common/components/Form/MessageSent';
import { Checkbox } from '../../common/components/Checkbox';
import Heading from '../../common/components/Heading';
import { LinkTag } from '../../common/components/Link';
import { PulseLoader } from 'react-spinners';
export var CampaignForm = function (_a) {
    var campaign = _a.campaign;
    var _b = useForm(), control = _b.control, handleSubmit = _b.handleSubmit;
    var _c = useMutation(CAMPAIGN_REQUEST), SendCampaignRequest = _c[0], _d = _c[1], data = _d.data, loading = _d.loading, error = _d.error;
    var onSubmit = function (data) {
        if (data.Approved) {
            SendCampaignRequest({
                variables: {
                    recipant: campaign.form.recipant,
                    organisation: data.Organisation,
                    name: data.Name,
                    email: data.Email,
                    motivation: data.Motivation,
                    phone: data.Phone,
                },
            });
        }
    };
    return (_jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: css.form }, { children: [_jsx(Heading, __assign({ as: "h3", className: css.heading }, { children: campaign.form.title })), (error || data) && (_jsx(MessageSent, { direction: "up", status: error || (data && (data === null || data === void 0 ? void 0 : data.sendCampaignRequest) === false) ? 'error' : 'success', subheading: error || (data && (data === null || data === void 0 ? void 0 : data.sendCampaignRequest) === false)
                    ? campaign.form.errorMessageTitle
                    : campaign.form.succesMessage, title: error || (data && (data === null || data === void 0 ? void 0 : data.sendCampaignRequest) === false)
                    ? error === null || error === void 0 ? void 0 : error.message
                    : campaign.form.succesMessageTitle, className: css.message })), loading && (_jsx("div", __assign({ className: css.loading }, { children: _jsx(PulseLoader, { color: "white" }) }))), !loading && !data && !error && (_jsxs(_Fragment, { children: [_jsx(Textfield, { control: control, label: campaign.form.organisationLabel, name: 'Organisation', required: true, type: "text" }), _jsx(Textfield, { control: control, label: campaign.form.nameLabel, name: 'Name', required: true, type: "text" }), _jsxs("span", __assign({ className: css.flex }, { children: [_jsx(Textfield, { control: control, label: campaign.form.emailLabel, name: 'Email', required: true, type: "email" }), _jsx(NumberInput, { control: control, label: campaign.form.phoneLabel, name: 'Phone', type: "tel" })] })), _jsx(Textfield, { control: control, label: campaign.form.motivationLabel, name: 'Motivation', required: true, type: "text", multiline: true }), _jsx("span", { children: _jsx(Checkbox, { control: control, name: 'Approved', label: _jsxs("p", { children: ["Jag godk\u00E4nner att Burdy lagrar mina uppgifter enligt", ' ', _jsx(LinkTag, __assign({ className: css.link, to: '/privacy-policy' }, { children: "Burdys integritetspolicy" }))] }), required: true }) }), _jsx(PrimaryButton, { type: "submit", text: campaign.form.buttonLabel })] }))] })));
};
