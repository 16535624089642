var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import Heading from '../../common/components/Heading';
import { CONTACT } from '../../graphql/queries/singleTypes/contactUs';
import { ContactForm } from './ContactForm';
import css from './ContactUs.module.scss';
import { ErrorMessage } from '../Error/Error';
import { Image } from '../../common/components/Image';
import { Text } from '../../common/components/Text';
import { Container } from '../../common/components/UI/Container';
export var Contact = function () {
    var _a = useQuery(CONTACT), data = _a.data, loading = _a.loading, error = _a.error;
    if (error)
        return _jsx(ErrorMessage, { title: 'find - Contact', errorMessage: error.message });
    if (loading)
        return _jsx(_Fragment, {});
    var contactUsData = data.contactUsSection.data.attributes;
    return (_jsx(Container, __assign({ id: "contact" }, { children: _jsxs(_Fragment, { children: [_jsx(Heading, __assign({ className: css.contactUsHeading, as: 'h2' }, { children: contactUsData.Title })), contactUsData.Text && _jsx(Text, __assign({ className: css.textSmallScreen }, { children: contactUsData.Text })), _jsxs("article", __assign({ className: css.ContentWrapper }, { children: [_jsx(ContactForm, { text: contactUsData.Text, errorMessage: contactUsData.ErrorMessage, btnText: contactUsData.ButtonText, succesMessage: contactUsData.SuccessMessage, succesSubMessage: contactUsData.SuccesMessageSubtext }), _jsx("div", __assign({ className: css.image }, { children: _jsx(Image, { fetchPriority: "auto", className: css.image, src: contactUsData.Image.data.attributes.url, alt: contactUsData.Image.data.attributes.alternativeText }) }))] }))] }) })));
};
