var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { Loader } from '../../common/components/Loader';
import { DataContext } from '../../root/Root';
import css from './nps.module.scss';
import Heading from '../../common/components/Heading';
import { Container } from '../../common/components/UI/Container';
import { NPSForm } from './NPSForm';
import appStyles from '../../app/App.module.scss';
import { Image } from '../../common/components/Image';
import { Error } from '../404';
import { useSearchParams } from 'react-router-dom';
export var NetPromoterScore = function () {
    var dataContext = useContext(DataContext);
    var navigationExpanded = dataContext.state.navigationExpanded;
    var searchParams = useSearchParams()[0];
    var token = searchParams.get('token');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var _a = useState({
        loading: true,
        error: null,
        data: null,
    }), state = _a[0], setState = _a[1];
    // const [isLoading, setIsLoading] = useState<boolean>(true)
    if (!token)
        return _jsx(Error, {});
    useEffect(function () {
        if (token) {
            ;
            (function () { return __awaiter(void 0, void 0, void 0, function () {
                var res, data_1, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, 4, 5]);
                            return [4 /*yield*/, fetch("".concat(process.env.REACT_APP_CMS_BASE_URL, "/api/nps?populate=*&token=").concat(token), {
                                    method: 'GET',
                                    headers: {
                                        Authorization: "Bearer ".concat(process.env.REACT_APP_JWT_TOKEN),
                                    },
                                })];
                        case 1:
                            res = _a.sent();
                            return [4 /*yield*/, res.json()];
                        case 2:
                            data_1 = _a.sent();
                            setState(function (prev) { return (__assign(__assign({}, prev), { data: data_1.data ? data_1.data.attributes : null, error: data_1.error ? data_1.error : null })); });
                            return [3 /*break*/, 5];
                        case 3:
                            error_1 = _a.sent();
                            setState(function (prev) { return (__assign(__assign({}, prev), { error: error_1 })); });
                            return [3 /*break*/, 5];
                        case 4:
                            setState(function (prev) { return (__assign(__assign({}, prev), { loading: false })); });
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            }); })();
        }
    }, []);
    if (state.loading)
        return _jsx(Loader, {});
    if (state.error)
        return _jsx(Error, {});
    return (_jsx("div", __assign({ className: appStyles.App }, { children: _jsx("div", __assign({ onClick: function () {
                return navigationExpanded && dataContext.setState(function (prev) { return (__assign(__assign({}, prev), { navigationExpanded: false })); });
            } }, { children: _jsxs(Container, __assign({ id: "", className: css.noMargin }, { children: [state.data.logo ? (_jsx(Image, { className: css.logo, alt: state.data.logo.data.attributes.alternativeText, src: state.data.logo.data.attributes.url, fetchPriority: "high" })) : null, _jsx(Heading, __assign({ className: css.heading, as: 'h1' }, { children: state.data.title })), _jsx(NPSForm, { nps: state.data })] })) })) })));
};
