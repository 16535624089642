// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wHU5dVhy_Km89wR4dFiu{display:flex;justify-content:center}.AkPrfOsH6rD9dU_u_AYQ{position:fixed;left:50%;top:40%;width:100%;height:100%;justify-content:center}.QpiJultDDiJomDlL_YOn{display:inline-block;vertical-align:middle}`, "",{"version":3,"sources":["webpack://./src/common/components/Loader/style.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,sBAAA,CAEJ,sBACI,cAAA,CACI,QAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,sBAAA,CAER,sBACI,oBAAA,CACA,qBAAA","sourcesContent":[".loadingWrapper{\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n.loadingWrapperFullHeight{\r\n    position: fixed;\r\n        left: 50%;\r\n        top: 40%;\r\n        width: 100%;\r\n        height: 100%;\r\n        justify-content: center;\r\n}\r\n.loading{\r\n    display: inline-block;\r\n    vertical-align: middle\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingWrapper": `wHU5dVhy_Km89wR4dFiu`,
	"loadingWrapperFullHeight": `AkPrfOsH6rD9dU_u_AYQ`,
	"loading": `QpiJultDDiJomDlL_YOn`
};
export default ___CSS_LOADER_EXPORT___;
