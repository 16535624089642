var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { TextCard } from '../../common/components/Card/TextCard';
import Heading from '../../common/components/Heading';
import Icon from 'react-icons-kit';
import css from './Carousel.module.scss';
import { socialLinkedin } from 'react-icons-kit/ionicons/socialLinkedin';
import { phone } from 'react-icons-kit/feather/phone';
import { ic_email_outline } from 'react-icons-kit/md/ic_email_outline';
import { Text } from '../../common/components/Text';
export var EmployeeCard = function (_a) {
    var selectedEmployee = _a.selectedEmployee;
    var openEmail = function (recipant) {
        window.location.href = 'mailto:' + recipant + "?subject=Jag \u00E4r er n\u00E4sta kollega";
    };
    var _b = useState(false), isExtended = _b[0], setIsExtended = _b[1];
    return (_jsx(TextCard, __assign({ className: "".concat(css.card, " ").concat(isExtended && css.extendedCard), heading: selectedEmployee.Name, cardFooter: _jsxs("div", __assign({ className: css.cardFooter }, { children: [_jsxs("div", __assign({ className: css.contact }, { children: [selectedEmployee.Phone && (_jsx("a", __assign({ "aria-label": 'phone', href: selectedEmployee.Phone, rel: "noreferrer" }, { children: _jsx(Icon, { icon: phone, size: 26 }) }))), selectedEmployee.LinkedIn && (_jsx("a", __assign({ "aria-label": 'linkedin link', href: selectedEmployee.LinkedIn, target: "_blank", rel: "noreferrer" }, { children: _jsx(Icon, { icon: socialLinkedin, size: 32 }) }))), selectedEmployee.Email && (_jsx(Icon, { icon: ic_email_outline, size: 30, onClick: function () { return openEmail(selectedEmployee.Email); } }))] })), selectedEmployee.Slogan && (_jsx(Text, __assign({ onClick: function () { return (isExtended ? setIsExtended(false) : setIsExtended(true)); } }, { children: selectedEmployee.Slogan })))] })) }, { children: _jsx(Heading, __assign({ as: 'h5', className: css.whiteText }, { children: selectedEmployee.Role })) })));
};
