var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from '../../common/components/Heading';
import { formatAboutData } from './AboutData';
import { useQuery } from '@apollo/client';
import { ABOUT } from '../../graphql/queries/singleTypes/aboutUs';
import css from './About.module.scss';
import { MarkdownText } from '../../common/components/Text';
import { ErrorMessage } from '../Error/Error';
import { Container } from '../../common/components/UI/Container';
import { ImageCard } from '../../common/components/Card/ImageCard';
import { SwiperCar } from '../Swiper/Swiper';
import { SwiperSlide } from 'swiper/react';
import { Image } from '../../common/components/Image';
var AboutUs = function () {
    var _a = useQuery(ABOUT), data = _a.data, loading = _a.loading, error = _a.error;
    if (error) {
        return _jsx(ErrorMessage, { title: 'find - About us', errorMessage: error.message });
    }
    if (loading)
        return _jsx(_Fragment, {});
    var aboutUsData = formatAboutData(data);
    return (_jsxs(Container, __assign({ id: "about" }, { children: [_jsx(Heading, __assign({ as: 'h2' }, { children: aboutUsData.title })), _jsxs("div", __assign({ className: css.contentWrapper }, { children: [_jsx(MarkdownText, __assign({ className: css.bodyText }, { children: aboutUsData.bodyText })), _jsx("div", __assign({ className: css.mainImage }, { children: _jsx(Image, { fetchPriority: "auto", src: aboutUsData.image.src, alt: aboutUsData.image.alt }) }))] })), _jsx("div", __assign({ className: css.subImagesWrapper }, { children: aboutUsData.subImages.map(function (x, i) { return (_jsx(ImageCard, { fetchPriority: "auto", style: {
                        width: aboutUsData.subImages.length <= 4
                            ? "calc(100% / ".concat(aboutUsData.subImages.length, " - 1em)")
                            : 'calc(25% - 1em)',
                    }, className: css.imageCard, overlayElement: _jsx(_Fragment, {}), src: x.src, alt: x.alt }, i)); }) })), _jsx("article", __assign({ className: css.carouselWrapper }, { children: _jsx(SwiperCar, __assign({ data: aboutUsData.subImages, bullets: true }, { children: aboutUsData.subImages.map(function (x, i) { return (_jsx(SwiperSlide, { children: _jsx(ImageCard, { fetchPriority: "auto", alt: x.alt, src: x.src, overlayElement: _jsx(_Fragment, {}) }) }, i)); }) })) }))] })));
};
export default AboutUs;
