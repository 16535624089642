import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { NumberInput } from '../common/components/Input/NumberInput';
import { Textfield } from '../common/components/Input/Textfield';
import { Checkbox } from '../common/components/Checkbox';
import css from '../containers/Contact/ContactUs.module.scss';
export var getFieldType = function (control, type, name, required) {
    switch (type) {
        case 'string':
            return (_jsx(Textfield, { label: name, required: required, multiline: false, type: 'text', name: name, control: control }));
        case 'text':
            return (_jsx(Textfield, { label: name, required: required, multiline: true, type: 'text', name: name, control: control }));
        case 'email':
            return (_jsx(Textfield, { className: css.smallInput, label: name, required: true, multiline: false, type: 'email', name: name, control: control }));
        case 'integer':
        case 'biginteger':
            return (_jsx(NumberInput, { className: css.smallInput, label: name, type: name === 'Telefonnummer' ? 'tel' : 'number', name: name, control: control }));
        case 'boolean':
            return (_jsx(Checkbox, { label: name === 'PolicyApproved'
                    ? 'Jag accepterar att Burdy lagrar mina uppgifter och kontaktar mig utifrån dessa.'
                    : name, name: name, control: control, required: required }));
        default:
            return _jsx(_Fragment, {});
    }
};
