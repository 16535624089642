var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import Heading from '../../common/components/Heading';
import { ROUTER_ERROR } from '../../graphql/queries/singleTypes/routerError';
import css from './Error.module.scss';
import { MarkdownText } from '../../common/components/Text';
import { ErrorMessage } from '../../containers/Error/Error';
export var Error = function () {
    var _a = useQuery(ROUTER_ERROR), data = _a.data, loading = _a.loading, error = _a.error;
    if (error)
        return _jsx(ErrorMessage, { title: 'find - 404 Error page', errorMessage: error.message });
    if (loading)
        return _jsx(_Fragment, {});
    var _data = data.routerError.data.attributes;
    return (_jsxs("div", __assign({ className: css.container }, { children: [_jsx(Heading, __assign({ as: 'h1' }, { children: _data.Title })), _data.BodyText && _jsx(MarkdownText, __assign({ className: css.markdown }, { children: _data.BodyText }))] })));
};
