var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import ReactGA from 'react-ga4';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import css from './Root.module.scss';
import { Text } from '../common/components/Text';
import { COOKIE_POLICY } from '../graphql/queries/singleTypes/cookiePolicy';
import Heading from '../common/components/Heading';
import { LinkTag } from '../common/components/Link';
import { initialDataContext, initialRootState } from '../helpers/ctxHelper';
import { Router } from './Router';
export var DataContext = createContext(initialDataContext);
export var Root = function () {
    var _a = useState(initialRootState), state = _a[0], setState = _a[1];
    var dataContextValue = {
        state: state,
        setState: setState,
    };
    var _b = useQuery(COOKIE_POLICY), data = _b.data, loading = _b.loading, error = _b.error;
    var cookiePolicyResponse = data === null || data === void 0 ? void 0 : data.cookiePolicy;
    var CookiePolicy = {
        Title: cookiePolicyResponse === null || cookiePolicyResponse === void 0 ? void 0 : cookiePolicyResponse.data.attributes.Title,
        Text: cookiePolicyResponse === null || cookiePolicyResponse === void 0 ? void 0 : cookiePolicyResponse.data.attributes.Text,
        AcceptBtnText: cookiePolicyResponse === null || cookiePolicyResponse === void 0 ? void 0 : cookiePolicyResponse.data.attributes.AcceptBtnText,
        DeclineBtnText: cookiePolicyResponse === null || cookiePolicyResponse === void 0 ? void 0 : cookiePolicyResponse.data.attributes.DeclineBtnText,
        Link: cookiePolicyResponse === null || cookiePolicyResponse === void 0 ? void 0 : cookiePolicyResponse.data.attributes.Link,
    };
    var handleDeclineCookie = function () {
        //remove google analytics cookies
        Cookies.remove('_ga');
        Cookies.remove('_gat');
        Cookies.remove('_gid');
    };
    var handleAcceptCookie = function () {
        var GA_ID = process.env.REACT_APP_GA_ID;
        ReactGA.initialize("".concat(GA_ID));
    };
    useEffect(function () {
        var isConsent = getCookieConsentValue();
        if (isConsent === 'true') {
            handleAcceptCookie();
        }
    }, []);
    return (_jsxs(DataContext.Provider, __assign({ value: dataContextValue }, { children: [!loading && !error ? (_jsxs(CookieConsent, __assign({ buttonClasses: css.primaryBtn, declineButtonClasses: css.secondaryBtn, containerClasses: css.cookieWrapper, contentClasses: css.cookieContentWrapper, buttonWrapperClasses: css.btnWrapper, buttonText: CookiePolicy.AcceptBtnText, declineButtonText: CookiePolicy.DeclineBtnText, enableDeclineButton: true, onAccept: handleAcceptCookie, onDecline: handleDeclineCookie }, { children: [CookiePolicy.Title && _jsx(Heading, __assign({ as: 'h5' }, { children: CookiePolicy.Title })), _jsx(Text, { children: CookiePolicy.Text }), CookiePolicy.Link && (_jsx(LinkTag, __assign({ to: CookiePolicy.Link }, { children: _jsx(Text, { children: 'Läs mer...' }) })))] }))) : null, _jsx(Router, {})] })));
};
