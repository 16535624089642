export var formatAboutData = function (data) {
    var _a, _b;
    var imageCollection = [];
    data.aboutSection.data.attributes.SubImages.data.map(function (x) {
        imageCollection.push({ alt: x.attributes.alternativeText, src: x.attributes.url });
    });
    return {
        title: data.aboutSection.data.attributes.Title,
        subHeading: data.aboutSection.data.attributes.Subheading,
        image: {
            src: (_a = data.aboutSection.data.attributes.Image.data) === null || _a === void 0 ? void 0 : _a.attributes.url,
            alt: (_b = data.aboutSection.data.attributes.Image.data) === null || _b === void 0 ? void 0 : _b.attributes.alternativeText,
        },
        subImages: imageCollection,
        bodyText: data.aboutSection.data.attributes.BodyText,
    };
};
