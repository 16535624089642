var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PrimaryButton } from '../../common/components/Button/PrimaryButton';
import { Text } from '../../common/components/Text';
import { Textfield } from '../../common/components/Input/Textfield';
import { Radio, RadioGroup } from '@fluentui/react-components';
import css from './nps.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { SEND_NPS } from '../../graphql/mutations/nps';
import { Loader } from '../../common/components/Loader';
import { useSearchParams } from 'react-router-dom';
export var NPSForm = function (_a) {
    var nps = _a.nps;
    var _b = useForm(), control = _b.control, handleSubmit = _b.handleSubmit;
    var searchParams = useSearchParams()[0];
    var token = searchParams.get('token');
    var _c = useMutation(SEND_NPS), SendNPS = _c[0], _d = _c[1], data = _d.data, loading = _d.loading, error = _d.error, reset = _d.reset;
    var getOptions = function () {
        var options = [];
        for (var i = 1; i <= 10; i++) {
            options.push(_jsx(Radio, { value: i.toString(), label: i }));
        }
        return options;
    };
    var onSubmit = function (data) {
        SendNPS({
            variables: {
                token: token,
                score: data.score,
                message: data.message,
            },
        });
    };
    return (_jsx("form", __assign({ className: css.form, onSubmit: handleSubmit(onSubmit) }, { children: loading ? (_jsx(Loader, {})) : error || (data && data.sendNPS === false) ? (_jsxs(_Fragment, { children: [_jsx(Text, { children: nps.errorMessage }), _jsx(Text, __assign({ underline: true, onClick: function () { return reset(); } }, { children: nps.tryAgainMessage }))] })) : data && data.sendNPS === true ? (_jsx(Text, { children: nps.successMessage })) : (_jsxs(_Fragment, { children: [_jsx(Controller, { name: 'score', control: control, rules: { required: true }, render: function (_a) {
                        var onChange = _a.field.onChange;
                        return (_jsxs("div", { children: [_jsx(Text, __assign({ className: css.scoreLabel }, { children: nps.scoreLabel })), _jsx(RadioGroup, __assign({ required: true, className: css.radioGroup, "aria-label": nps.scoreLabel, layout: "horizontal-stacked", onChange: function (e, data) { return onChange(Number(data.value)); } }, { children: getOptions() }))] }));
                    } }), _jsxs("div", __assign({ className: css.spaceBtw }, { children: [_jsx(Text, __assign({ className: css.minMaxLabel }, { children: nps.minValueLabel })), _jsx(Text, __assign({ className: css.minMaxLabel }, { children: nps.maxValueLabel }))] })), _jsx(Textfield, { className: css.messageField, control: control, multiline: true, label: nps.messageLabel, name: 'message', type: "text" }), _jsx(PrimaryButton, { text: nps.btnText, type: "submit" })] })) })));
};
