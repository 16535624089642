var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import css from './Carousel.module.scss';
import { EmployeeCard } from './EmployeeCard';
export var CarouselCircle = function (_a) {
    var employees = _a.employees;
    var _b = useState(employees.length > 3 && employees.length <= 9 && window.matchMedia('(max-width: 960px)').matches
        ? employees[2]
        : employees[0]), selectedEmployee = _b[0], setSelectedEmployee = _b[1];
    var angle = 360 / employees.length;
    return (_jsxs("div", __assign({ className: css.wrapper }, { children: [_jsx(EmployeeCard, { selectedEmployee: selectedEmployee }), _jsx("div", __assign({ className: "".concat(employees.length > 3 && employees.length <= 9 ? css.avatarsCircleWrapper : css.avatarsFlexWrapper) }, { children: employees.map(function (x, i) { return (_jsx("img", { src: x.ProfilePicture.src, alt: x.ProfilePicture.alt, onClick: function () { return setSelectedEmployee(x); }, style: employees.length > 3 && employees.length <= 9
                        ? x.Name === selectedEmployee.Name
                            ? window.matchMedia('(max-width: 480px)').matches
                                ? {
                                    transition: 'transform 0.3s',
                                    transform: "scale(2)",
                                }
                                : {
                                    transition: 'transform 0.3s',
                                    transform: "scale(2)",
                                }
                            : window.matchMedia('(max-width: 480px)').matches
                                ? {
                                    transition: 'transform 0.3s',
                                    transform: "rotate(".concat(i * angle * 1, "deg) translate(-110px) rotate(").concat(i * angle * -1, "deg)"),
                                }
                                : {
                                    transition: 'transform 0.3s',
                                    transform: "rotate(".concat(i * angle * 1, "deg) translate(-170px) rotate(").concat(i * angle * -1, "deg)"),
                                }
                        : {}, className: "".concat(css.profilePic, " ").concat(x.Name === selectedEmployee.Name && css.selectedEmployee, " ").concat(x.Name !== selectedEmployee.Name && css.notSelectedEmployee, " ") }, i)); }) }))] })));
};
