export var getServicesData = function (data) {
    var services = [];
    // eslint-disable-next-line array-callback-return
    data === null || data === void 0 ? void 0 : data.services.data.map(function (x) {
        var _a, _b;
        services.push({
            Title: x === null || x === void 0 ? void 0 : x.attributes.Title,
            Description: x === null || x === void 0 ? void 0 : x.attributes.Description,
            Image: {
                src: (_a = x === null || x === void 0 ? void 0 : x.attributes.Image.data) === null || _a === void 0 ? void 0 : _a.attributes.url,
                alt: (_b = x === null || x === void 0 ? void 0 : x.attributes.Image.data) === null || _b === void 0 ? void 0 : _b.attributes.alternativeText,
            },
            Link: x.attributes.Link,
            LinkTitle: x.attributes.LinkTitle,
            ServiceId: x.attributes.ServiceId,
        });
    });
    return {
        Title: data === null || data === void 0 ? void 0 : data.Title,
        Subheading: data === null || data === void 0 ? void 0 : data.Subheading,
        Services: services,
    };
};
