var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fade } from 'react-awesome-reveal';
import Heading from '../../common/components/Heading';
import BlogPost from '../../common/components/BlogPost';
import { useQuery } from '@apollo/client';
import { getServicesData } from './ServiceData';
import { SERVICESSECTION } from '../../graphql/queries/singleTypes/servicesSection';
import css from './Services.module.scss';
import { ErrorMessage } from '../Error/Error';
import { Container } from '../../common/components/UI/Container';
var Services = function () {
    var _a;
    var _b = useQuery(SERVICESSECTION), loading = _b.loading, error = _b.error, data = _b.data;
    if (loading)
        return _jsx(_Fragment, {});
    if (error)
        return _jsx(ErrorMessage, { title: 'find - Customers section', errorMessage: error.message });
    var serviceData = getServicesData((_a = data.servicesSection.data) === null || _a === void 0 ? void 0 : _a.attributes);
    return (_jsxs(Container, __assign({ id: "services" }, { children: [_jsxs(Fade, __assign({ triggerOnce: true, fraction: 0.5, direction: "up" }, { children: [_jsx(Heading, __assign({ as: 'h2', className: css.heading }, { children: serviceData.Title })), serviceData.Subheading && _jsx(Heading, __assign({ as: 'h3' }, { children: serviceData.Subheading }))] })), _jsx("section", { children: serviceData.Services.map(function (item, i) { return (_jsx(BlogPost, { id: item.ServiceId, src: item.Image.src, title: item.Title, desc: item.Description, link: item.Link, linkTitle: item.LinkTitle, alt: item.Image.alt }, "service_key".concat(i))); }) })] })));
};
export default Services;
