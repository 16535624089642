var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from '../Heading';
import css from './BlogPost.module.scss';
import { Fade } from 'react-awesome-reveal';
import { MarkdownText } from '../Text';
import { LinkTag } from '../../components/Link';
import { ArrowRightFilled } from '@fluentui/react-icons';
import { Image } from '../Image';
var BlogPost = function (_a) {
    var className = _a.className, src = _a.src, title = _a.title, desc = _a.desc, link = _a.link, linkTitle = _a.linkTitle, id = _a.id, alt = _a.alt;
    return (_jsxs("div", __assign({ className: "".concat(className, " ").concat(css.blogPostWrapper), id: id }, { children: [_jsxs("div", __assign({ className: css.content }, { children: [_jsx(Heading, __assign({ as: 'h5', className: css.blogPostTitle }, { children: title })), _jsx(MarkdownText, __assign({ className: css.excerpt }, { children: desc })), link && (_jsx(LinkTag, __assign({ to: link }, { children: _jsxs("div", __assign({ className: css.link }, { children: [_jsx("p", { children: linkTitle }), _jsx(ArrowRightFilled, {})] })) })))] })), _jsx(Fade, __assign({ direction: "up", cascade: true, triggerOnce: true, fraction: 0.5 }, { children: _jsx("div", __assign({ className: "".concat(css.image, " ").concat(title === 'Konsulter' && css.positionedImage) }, { children: _jsx(Image, { fetchPriority: "auto", src: src, alt: alt }) })) }))] })));
};
export default BlogPost;
