var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from '../../common/components/Heading';
import { MarkdownText } from '../../common/components/Text';
import { Container } from '../../common/components/UI/Container';
import { getCustomerData } from './CustomerData';
import { CUSTOMERSECTION } from '../../graphql/queries/singleTypes/customerSection';
import { useQuery } from '@apollo/client';
import css from './Customers.module.scss';
import { ErrorMessage } from '../Error/Error';
import Logo from '../../common/components/UIElements/Logo';
import { TextCard } from '../../common/components/Card/TextCard';
var Customers = function () {
    var _a = useQuery(CUSTOMERSECTION), loading = _a.loading, error = _a.error, data = _a.data;
    if (error)
        return _jsx(ErrorMessage, { title: 'find - Customers section', errorMessage: error.message });
    if (loading)
        return _jsx(_Fragment, {});
    var customerData = {
        title: '',
        subheading: '',
        text: '',
        customers: [],
    };
    if (data)
        customerData = getCustomerData(data);
    return (_jsxs(Container, __assign({ id: "customers" }, { children: [_jsxs("header", __assign({ className: css.header }, { children: [_jsx(Heading, __assign({ as: 'h2' }, { children: customerData.title })), _jsx(Heading, __assign({ as: 'h3' }, { children: customerData.subheading })), _jsx(MarkdownText, { children: customerData.text })] })), _jsx("section", __assign({ className: css.cardWrapper }, { children: customerData.customers.map(function (x, i) {
                    var _a, _b;
                    return (_jsx(TextCard, { className: css.card, overlayElement: _jsx(Logo, { fetchPriority: "low", className: css.customerLogo, withAnchor: false, src: (_a = x.logo) === null || _a === void 0 ? void 0 : _a.src, alt: (_b = x.logo) === null || _b === void 0 ? void 0 : _b.alt }) }, i)
                    // <ImageCard fetchPriority='auto'
                    //     key={i}
                    //     className={css.card}
                    //     alt={x.title}
                    //     src={x.image?.src}
                    //     overlayElement={
                    //         <Logo
                    //             className={css.customerLogo}
                    //             withAnchor={false}
                    //             src={x.logo?.src}
                    //             alt={`${x.title} logo`}
                    //         />
                    //     }
                    // />
                    );
                }) }))] })));
};
export default Customers;
