//eslint-disable-next-line @typescript-eslint/no-explicit-any
export var formatTeamData = function (data) {
    var employees = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data.teamSection.data.attributes.employees.data.map(function (x) {
        var _a, _b, _c, _d, _e;
        employees.push({
            Id: x.id,
            Role: (_a = x.attributes.employee_role.data) === null || _a === void 0 ? void 0 : _a.attributes.title,
            Name: x.attributes.name,
            LinkedIn: x.attributes.linkedin,
            Phone: x.attributes.phone,
            Email: x.attributes.email,
            Skills: [],
            ProfilePicture: {
                src: (_b = x.attributes.profilePicture.data.attributes) === null || _b === void 0 ? void 0 : _b.url,
                alt: (_c = x.attributes.profilePicture.data.attributes) === null || _c === void 0 ? void 0 : _c.alternativeText,
            },
            ProfilePicture_BW: {
                src: (_d = x.attributes.bwProfilePicture.data.attributes) === null || _d === void 0 ? void 0 : _d.url,
                alt: (_e = x.attributes.bwProfilePicture.data.attributes) === null || _e === void 0 ? void 0 : _e.alternativeText,
            },
            Slogan: x.attributes.slogan,
        });
    });
    return {
        title: data.teamSection.data.attributes.Title,
        subHeading: data.teamSection.data.attributes.Subheading,
        text: data.teamSection.data.attributes.Text,
        employees: employees,
    };
};
