var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import css from './Checkbox.module.scss';
export var Checkbox = function (_a) {
    var control = _a.control, name = _a.name, label = _a.label, required = _a.required, dark = _a.dark;
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var _b = _a.field, onChange = _b.onChange, value = _b.value, error = _a.fieldState.error;
            return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "".concat(css.checkboxWrapper, " ").concat(dark ? css.dark : '') }, { children: [_jsx("input", { required: required, maxLength: 10, type: 'checkbox', id: name, onChange: onChange, value: value !== null && value !== void 0 ? value : '' }), _jsx("label", __assign({ htmlFor: name }, { children: label }))] })), error && _jsx("p", __assign({ className: "".concat(css.errorMessage) }, { children: "V\u00E4nligen kryssa i rutan" }))] }));
        } }));
};
