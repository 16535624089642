var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Root } from './root/Root';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { FluentProvider, createDarkTheme } from '@fluentui/react-components';
var httpLink = createHttpLink({
    uri: "".concat(process.env.REACT_APP_CMS_BASE_URL, "/graphql"),
});
var authLink = setContext(function (_, _a) {
    var headers = _a.headers;
    // get the authentication token from local storage if it exists
    var token = process.env.REACT_APP_JWT_TOKEN;
    // return the headers to the context so httpLink can read them
    return {
        headers: __assign(__assign({}, headers), { authorization: token ? "Bearer ".concat(token) : '' }),
    };
});
var client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});
var burdy = {
    10: '#010305',
    20: '#0E191F',
    30: '#112A36',
    40: '#103748',
    50: '#0C455B',
    60: '#02536E',
    70: '#235F7A',
    80: '#3A6C85',
    90: '#4E7990',
    100: '#61879B',
    110: '#7394A7',
    120: '#86A2B2',
    130: '#98B0BE',
    140: '#ABBECA',
    150: '#BECDD6',
    160: '#D1DBE2',
};
var darkTheme = __assign({}, createDarkTheme(burdy));
darkTheme.colorBrandForeground1 = burdy[110];
darkTheme.colorBrandForeground2 = burdy[120];
var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(React.StrictMode, { children: _jsx(FluentProvider, __assign({ theme: darkTheme }, { children: _jsx(ApolloProvider, __assign({ client: client }, { children: _jsx(Root, {}) })) })) }));
