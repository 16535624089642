// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RIJ80v9ga5v3B8cOllh8{margin:1em auto;min-height:100svh;padding:40px 20px 0 20px}`, "",{"version":3,"sources":["webpack://./src/common/components/UI/Container/Container.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,iBAAA,CACA,wBAAA","sourcesContent":[".container {\r\n    margin: 1em auto;\r\n    min-height: 100svh;\r\n    padding: 40px 20px 0 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RIJ80v9ga5v3B8cOllh8`
};
export default ___CSS_LOADER_EXPORT___;
