import { jsx as _jsx } from "react/jsx-runtime";
import css from './Image.module.scss';
export var Image = function (_a) {
    var src = _a.src, alt = _a.alt, className = _a.className, fetchPriority = _a.fetchPriority;
    var azureCdnUrl = 'https://burdy-cdn.azureedge.net/';
    var azureStorageUrl = 'https://cmsburdystorage.blob.core.windows.net/';
    var SrcToCdnUrl = function (src) {
        if (src === null || src === void 0 ? void 0 : src.startsWith(azureStorageUrl))
            return src.replace(azureStorageUrl, azureCdnUrl);
        return src;
    };
    return (
    // eslint-disable-next-line react/no-unknown-property
    _jsx("img", { fetchpriority: fetchPriority, className: "".concat(className, " ").concat(css.image), src: SrcToCdnUrl(src), alt: alt }));
};
