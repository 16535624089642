var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Heading from '../Heading';
import css from './Card.module.scss';
export var TextCard = function (_a) {
    var heading = _a.heading, children = _a.children, cardFooter = _a.cardFooter, className = _a.className, overlayElement = _a.overlayElement;
    return (_jsxs("div", __assign({ className: "".concat(css.card, " ").concat(className) }, { children: [heading && _jsx(Heading, __assign({ as: 'h3' }, { children: heading })), children, cardFooter, overlayElement] })));
};
