import { jsx as _jsx } from "react/jsx-runtime";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Home } from '../pages/Home';
import { App } from '../app/App';
import { Error } from '../pages/404';
import { Campaign } from '../pages/Campaign';
import { Policy } from '../pages/Policy';
import { NetPromoterScore } from '../pages/nps';
export var Router = function () {
    var router = createBrowserRouter([
        {
            path: '/',
            element: _jsx(App, {}),
            errorElement: _jsx(Error, {}),
            children: [
                {
                    path: '/',
                    element: _jsx(Home, {}),
                },
                {
                    path: '/kampanj',
                    element: _jsx(Campaign, {}),
                },
                {
                    path: '/privacy-policy',
                    element: _jsx(Policy, {}),
                },
                {
                    path: '*',
                    element: _jsx(Error, {}),
                },
            ],
        },
        {
            path: '/nps',
            element: _jsx(NetPromoterScore, {}),
            errorElement: _jsx(Error, {}),
        },
    ]);
    return _jsx(RouterProvider, { router: router });
};
