// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AseP6tTudgb8nZwBPebb{text-align:left;padding-bottom:150px}@media only screen and (max-width: 1440px){.AseP6tTudgb8nZwBPebb{padding-bottom:100px}}@media only screen and (max-width: 991px){.AseP6tTudgb8nZwBPebb{padding-bottom:40px}}.wZ7hMfaXZNz6KBqnR4rW{display:flex;align-items:center;justify-content:center;width:100%}`, "",{"version":3,"sources":["webpack://./src/containers/Team/Team.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,oBAAA,CACA,2CAHJ,sBAIQ,oBAAA,CAAA,CAEJ,0CANJ,sBAOQ,mBAAA,CAAA,CAGR,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA","sourcesContent":[".header {\r\n    text-align: left;\r\n    padding-bottom: 150px;\r\n    @media only screen and (max-width: 1440px) {\r\n        padding-bottom: 100px;\r\n    }\r\n    @media only screen and (max-width: 991px) {\r\n        padding-bottom: 40px;\r\n    }\r\n}\r\n.CarouselWrapper {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `AseP6tTudgb8nZwBPebb`,
	"CarouselWrapper": `wZ7hMfaXZNz6KBqnR4rW`
};
export default ___CSS_LOADER_EXPORT___;
