var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Sticky from 'react-stickynode';
import Navbar from '../common/components/Navbar';
import { Footer } from '../containers/Footer';
import css from './App.module.scss';
import { Outlet } from 'react-router-dom';
export var App = function () {
    return (_jsxs("div", __assign({ className: css.App }, { children: [_jsx(Sticky, __assign({ top: 0, innerZ: 999 }, { children: _jsx(Navbar, {}) })), _jsx("main", __assign({ className: css.main }, { children: _jsx(Outlet, {}) })), _jsx(Footer, {})] })));
};
