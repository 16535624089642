import { ic_facebook } from 'react-icons-kit/md/ic_facebook';
import { instagram } from 'react-icons-kit/fa/instagram';
import { socialLinkedin } from 'react-icons-kit/ionicons/socialLinkedin';
import { twitter } from 'react-icons-kit/icomoon/twitter';
import { mug } from 'react-icons-kit/icomoon/mug';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var getMediaIcon = function (media) {
    switch (media) {
        case 'instagram':
            return instagram;
        case 'facebook':
            return ic_facebook;
        case 'linkedin':
            return socialLinkedin;
        case 'twitter':
            return twitter;
        default:
            return mug;
    }
};
