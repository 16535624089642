var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import HamburgMenu from '../HamburgMenu';
import css from './Navbar.module.scss';
import { useQuery } from '@apollo/client';
import { NAVIGATIONS_AND_SITELOGO } from '../../../graphql/queries/collectionTypes/navigation';
import { Link } from 'react-router-dom';
import { ErrorMessage } from '../../../containers/Error/Error';
import { HashLink } from 'react-router-hash-link';
import { DataContext } from '../../../root/Root';
import Logo from '../UIElements/Logo';
var Navbar = function () {
    var dataContext = useContext(DataContext);
    var setState = dataContext.setState;
    var navigationExpanded = dataContext.state.navigationExpanded;
    var _a = useQuery(NAVIGATIONS_AND_SITELOGO), loading = _a.loading, data = _a.data, error = _a.error;
    if (error)
        return _jsx(ErrorMessage, { title: 'find - Navbar', errorMessage: error.message });
    if (loading)
        return _jsx(_Fragment, {});
    var logo = data.logo;
    var navs = data.navigations;
    return (_jsxs("div", __assign({ className: css.sticky }, { children: [_jsx("nav", __assign({ className: css.nav }, { children: _jsxs("div", __assign({ className: css.navContent }, { children: [_jsx("div", __assign({ className: "".concat(css.burdyLogo, " ") }, { children: _jsx(Logo, { fetchPriority: "low", anchorLink: '/', withAnchor: true, src: logo.data.attributes.SiteLogo.data.attributes.url, alt: "Burdy" }) })), _jsx(HamburgMenu, {})] })) })), navigationExpanded && (_jsx("div", __assign({ className: css.menuitemsWrapper }, { children: navs.data.map(function (x, i) {
                    return x.attributes.Hash ? (_jsx(HashLink, __assign({ to: window.location.pathname === '/'
                            ? "".concat(x.attributes.Hash)
                            : "".concat(x.attributes.Path).concat(x.attributes.Hash), onClick: function () {
                            return setState(function (prev) { return (__assign(__assign({}, prev), { navigationExpanded: !prev.navigationExpanded })); });
                        } }, { children: x.attributes.Title }), i)) : (_jsx(Link, __assign({ to: { pathname: x.attributes.Path }, onClick: function () {
                            return setState(function (prev) { return (__assign(__assign({}, prev), { navigationExpanded: !prev.navigationExpanded })); });
                        } }, { children: x.attributes.Title }), i));
                }) })))] })));
};
export default Navbar;
