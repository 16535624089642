import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { ERROR } from '../../graphql/mutations/error';
import { useMutation } from '@apollo/client';
export var ErrorMessage = function (_a) {
    var title = _a.title, errorMessage = _a.errorMessage;
    var LogError = useMutation(ERROR)[0];
    useEffect(function () {
        if (window.location.hostname !== 'localhost') {
            LogError({
                variables: {
                    title: title,
                    error: errorMessage,
                    app: 'external-web',
                    recipant: 'internsupport@burdy.se',
                },
            });
        }
    }, []);
    return _jsx(_Fragment, {});
};
