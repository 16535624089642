var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { Banner } from '../../containers/Banner';
import Services from '../../containers/Services';
import AboutUs from '../../containers/About';
import Customers from '../../containers/Customers';
import { Team } from '../../containers/Team';
import { Contact } from '../../containers/Contact';
import { Loader } from '../../common/components/Loader';
import css from '../../root/Root.module.scss';
import { DataContext } from '../../root/Root';
export var Home = function () {
    var dataContext = useContext(DataContext);
    var navigationExpanded = dataContext.state.navigationExpanded;
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    return (_jsxs("div", __assign({ onClick: function () {
            return navigationExpanded && dataContext.setState(function (prev) { return (__assign(__assign({}, prev), { navigationExpanded: false })); });
        } }, { children: [isLoading ? (_jsx("div", __assign({ className: css.fullHeightAuto }, { children: _jsx(Loader, { size: 100, fullHeight: true }) }))) : (_jsx(_Fragment, {})), _jsxs("div", __assign({ className: isLoading ? css.hidden : undefined }, { children: [_jsx(Banner, { setIsLoading: setIsLoading }), _jsx(Services, {}), _jsx(Customers, {}), _jsx(AboutUs, {}), _jsx(Team, {}), _jsx(Contact, {})] }))] })));
};
