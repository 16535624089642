var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import Heading from '../../common/components/Heading';
import { BANNER } from '../../graphql/queries/singleTypes/banner';
import { useQuery } from '@apollo/client';
import Logo from '../../common/components/UIElements/Logo';
import css from './Banner.module.scss';
import { ErrorMessage } from '../Error/Error';
import { SwiperCar } from '../Swiper/Swiper';
import { SwiperSlide } from 'swiper/react';
import { ImageCard } from '../../common/components/Card/ImageCard';
import { Icon } from 'react-icons-kit';
import { arrowRight2 } from 'react-icons-kit/icomoon/arrowRight2';
import { LinkTag } from '../../common/components/Link';
export var Banner = function (_a) {
    var _b, _c, _d;
    var setIsLoading = _a.setIsLoading;
    var _e = useQuery(BANNER), loading = _e.loading, data = _e.data, error = _e.error;
    useEffect(function () {
        if (!loading) {
            setIsLoading(false);
        }
    });
    if (error)
        return _jsx(ErrorMessage, { title: 'find - Banner', errorMessage: error.message });
    if (loading)
        return _jsx(_Fragment, {});
    var bannerData = (_c = (_b = data === null || data === void 0 ? void 0 : data.banner) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.attributes;
    return (_jsxs("article", __assign({ className: css.bannerWrapper, id: 'home' }, { children: [_jsxs("div", __assign({ className: css.dynamicBanner }, { children: [_jsx("header", __assign({ className: css.header }, { children: _jsx(Fade, __assign({ direction: "up" }, { children: _jsxs("span", { children: [_jsx("div", { children: _jsx(Logo, { fetchPriority: "high", withAnchor: false, className: css.logo, src: bannerData.Logo.data.attributes.url, alt: bannerData.Logo.data.attributes.alternativeText }) }), _jsx(Heading, __assign({ as: 'h3', className: css.slogan }, { children: bannerData.Slogan }))] }) })) })), _jsx(SwiperCar, __assign({ className: css.swiper, data: [
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (_d = bannerData.banner_carousel_medias) === null || _d === void 0 ? void 0 : _d.data.map(function (x) { return ({
                                id: x.id,
                                image: {
                                    src: x.attributes.Image.data.attributes.url,
                                    alt: x.attributes.Image.data.attributes.alternativeText,
                                },
                                title: x.attributes.Title,
                                link: x.attributes.Link,
                            }); }),
                        ], bullets: false, breakpoints: {
                            570: {
                                slidesPerView: 2,
                            },
                            767: {
                                slidesPerView: 3,
                            },
                            991: {
                                slidesPerView: 2,
                            },
                            1200: {
                                slidesPerView: 3,
                            },
                        } }, { children: bannerData.banner_carousel_medias.data.map(function (x, i) { return (_jsx(SwiperSlide, { children: _jsx(ImageCard, { fetchPriority: "high", className: css.imageCard, alt: x.attributes.Image.data.attributes.alternativeText, src: x.attributes.Image.data.attributes.url, overlayElement: _jsx(LinkTag, __assign({ to: x.attributes.Link, className: css.hoverElement }, { children: _jsx("div", { children: _jsxs("p", { children: [x.attributes.Title, " ", _jsx(Icon, { icon: arrowRight2, size: 40 })] }) }) })) }) }, i)); }) }))] })), _jsx("section", __assign({ className: css.staticImages }, { children: bannerData.banner_carousel_medias.data.map(function (x, i) { return (_jsx(ImageCard, { fetchPriority: "high", className: css.imageCard, alt: x.attributes.Image.data.attributes.alternativeText, src: x.attributes.Image.data.attributes.url, overlayElement: _jsx(LinkTag, __assign({ to: x.attributes.Link, className: css.hoverElement }, { children: _jsx("div", { children: _jsxs("p", { children: [x.attributes.Title, " ", _jsx(Icon, { icon: arrowRight2, size: 40 })] }) }) })) }, i)); }) }))] })));
};
