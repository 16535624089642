var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactMarkdown from 'react-markdown';
import css from './Text.module.scss';
export var Text = function (_a) {
    var children = _a.children, className = _a.className, onClick = _a.onClick, bold = _a.bold, italic = _a.italic, strikeThrough = _a.strikeThrough, underline = _a.underline, inline = _a.inline;
    return (_jsx("p", __assign({ className: "".concat(css.text, " ").concat(bold ? css.bold : '', " ").concat(strikeThrough ? css.strikeThrough : '', " ").concat(italic ? css.italic : '', " ").concat(underline ? css.underline : '', " ").concat(inline ? css.inline : '', " ").concat(className !== null && className !== void 0 ? className : '', " "), onClick: onClick }, { children: children })));
};
export var MarkdownText = function (_a) {
    var children = _a.children, className = _a.className;
    return (_jsx(ReactMarkdown, __assign({ className: "".concat(css.text, " ").concat(className) }, { children: children })));
};
