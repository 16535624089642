var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Image } from '../../Image';
import { Link } from 'react-router-dom';
var Logo = function (_a) {
    var withAnchor = _a.withAnchor, src = _a.src, alt = _a.alt, anchorLink = _a.anchorLink, className = _a.className, fetchPriority = _a.fetchPriority;
    return (_jsx(_Fragment, { children: withAnchor ? (_jsxs(Link, __assign({ to: anchorLink !== null && anchorLink !== void 0 ? anchorLink : '', className: className }, { children: [_jsx(Image, { fetchPriority: fetchPriority, className: className, src: src, alt: alt }), ' '] }))) : (_jsx(Image, { fetchPriority: fetchPriority, className: className, src: src, alt: alt })) }));
};
export default Logo;
