var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useContext } from 'react';
import Heading from '../../common/components/Heading';
import { Text } from '../../common/components/Text';
import css from './Campaign.module.scss';
import { Container } from '../../common/components/UI/Container';
import { useQuery } from '@apollo/client';
import { CAMPAIGN } from '../../graphql/queries/singleTypes/campaign';
import { Loader } from '../../common/components/Loader';
import { generateHTMLFromRichText } from '../../helpers/markdownHelper';
import { CampaignForm } from './CampaignForm';
import { DataContext } from '../../root/Root';
import { Error } from '../404';
export var Campaign = function () {
    var _a = useQuery(CAMPAIGN), data = _a.data, loading = _a.loading, error = _a.error;
    var dataContext = useContext(DataContext);
    var navigationExpanded = dataContext.state.navigationExpanded;
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(Error, {});
    var campaign = data === null || data === void 0 ? void 0 : data.campaign.data.attributes.Campaign;
    return (_jsxs("article", __assign({ onClick: function () {
            return navigationExpanded && dataContext.setState(function (prev) { return (__assign(__assign({}, prev), { navigationExpanded: false })); });
        } }, { children: [_jsxs(Container, __assign({ id: 'how', className: css.noMinWidth }, { children: [_jsx(Heading, __assign({ as: 'h1' }, { children: campaign.title })), _jsxs("article", __assign({ className: css.flexWrapper }, { children: [_jsx("section", { children: generateHTMLFromRichText(campaign.description) }), _jsx("section", { children: campaign.steps.campaign_steps.data.map(function (x, i) { return (_jsxs(Fragment, { children: [_jsxs("div", __assign({ className: css.box }, { children: [_jsx(Heading, __assign({ as: 'h4' }, { children: x.attributes.title })), _jsx(Text, { children: x.attributes.text })] })), i !== campaign.steps.campaign_steps.data.length - 1 && _jsx("div", { className: css.line })] }, i)); }) })] }))] })), _jsx(Container, __assign({ id: 'form', className: "".concat(css.flexWrapper, " ").concat(css.noMinWidth) }, { children: _jsx(CampaignForm, { campaign: campaign }) }))] })));
};
