var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { PrimaryButton } from '../../common/components/Button/PrimaryButton';
import { useMutation } from '@apollo/client';
import { getFieldType } from '../../helpers/formHelper';
import { SEND_CONTACT_REQ_MINI } from '../../graphql/mutations/contactRequestMinimal';
import { useForm } from 'react-hook-form';
import css from './ContactUs.module.scss';
import { sendContactRequestMinimal, getContactFormMinimalFields } from '../../helpers/contactRequestHelper';
import { Text } from '../../common/components/Text';
import MessageSent from '../../common/components/Form/MessageSent';
export var ContactForm = function (_a) {
    var text = _a.text, errorMessage = _a.errorMessage, succesMessage = _a.succesMessage, succesSubMessage = _a.succesSubMessage, btnText = _a.btnText;
    var _b = useMutation(SEND_CONTACT_REQ_MINI), SendContactRequestMinimal = _b[0], _c = _b[1], data = _c.data, loading = _c.loading, error = _c.error;
    var _d = useState([]), fields = _d[0], setFields = _d[1];
    var _e = useState(false), isRequestSent = _e[0], setIsRequestSent = _e[1];
    var _f = useForm(), handleSubmit = _f.handleSubmit, control = _f.control, reset = _f.reset;
    var onSubmit = function (request) {
        sendContactRequestMinimal(request, SendContactRequestMinimal, reset);
    };
    useEffect(function () {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getContactFormMinimalFields().then(function (res) {
            setFields(res);
        });
    }, []);
    useEffect(function () {
        if (data) {
            setIsRequestSent(true);
            setTimeout(function () {
                setIsRequestSent(false);
            }, 10000);
        }
    }, [data]);
    return (_jsxs("form", __assign({ className: css.formWrapper, onSubmit: handleSubmit(onSubmit) }, { children: [Text && _jsx(Text, __assign({ className: css.textLargeScreen }, { children: text !== null && text !== void 0 ? text : '' })), error ? (_jsx(MessageSent, { status: "error", subheading: error === null || error === void 0 ? void 0 : error.message, title: errorMessage, className: css.absolute })) : loading ? (_jsx("div", __assign({ className: "".concat(css.absolute, " ").concat(css.loading) }, { children: _jsx(PulseLoader, { color: "white" }) }))) : isRequestSent ? (_jsx(MessageSent, { status: "success", subheading: succesSubMessage, title: succesMessage, className: css.absolute })) : (_jsx(_Fragment, {})), _jsxs("div", __assign({ className: "".concat(css.form, " ").concat(error || loading || isRequestSent ? css.hidden : undefined) }, { children: [fields.map(function (x, i) { return (_jsx("div", __assign({ className: "".concat(x.type === 'integer' || x.type === 'email' ? css.smallFormGroup : css.formGroup) }, { children: getFieldType(control, x.type, x.name, x.required) }), i)); }), _jsx(PrimaryButton, { type: "submit", text: btnText, className: css.formButton })] }))] })));
};
