// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sr2hLmHznzRfPFox78Bf{margin-top:60px;margin-left:200px;display:flex;flex-direction:column;text-align:center;align-items:center}@media only screen and (max-width: 1600px){.Sr2hLmHznzRfPFox78Bf{margin-left:120px}}@media only screen and (max-width: 1400px){.Sr2hLmHznzRfPFox78Bf{margin-left:70px}}@media only screen and (max-width: 1220px){.Sr2hLmHznzRfPFox78Bf{margin-left:30px}}@media only screen and (max-width: 991px){.Sr2hLmHznzRfPFox78Bf{margin-left:0;width:100%;margin-top:150px}}.Sr2hLmHznzRfPFox78Bf .SzqvXoU6yWUZJKo1LxPl{color:#fff}.Sr2hLmHznzRfPFox78Bf .bhlUeRrXKAc9zuCiKA1W{color:red}.Sr2hLmHznzRfPFox78Bf p{color:#fff}`, "",{"version":3,"sources":["webpack://./src/common/components/Form/Message.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,kBAAA,CACA,2CAPJ,sBAQQ,iBAAA,CAAA,CAEJ,2CAVJ,sBAWQ,gBAAA,CAAA,CAEJ,2CAbJ,sBAcQ,gBAAA,CAAA,CAEJ,0CAhBJ,sBAiBQ,aAAA,CACA,UAAA,CACA,gBAAA,CAAA,CAEJ,4CACI,UAAA,CAEJ,4CACI,SAAA,CAEJ,wBACI,UAAA","sourcesContent":[".message {\r\n    margin-top: 60px;\r\n    margin-left: 200px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    text-align: center;\r\n    align-items: center;\r\n    @media only screen and (max-width: 1600px) {\r\n        margin-left: 120px;\r\n    }\r\n    @media only screen and (max-width: 1400px) {\r\n        margin-left: 70px;\r\n    }\r\n    @media only screen and (max-width: 1220px) {\r\n        margin-left: 30px;\r\n    }\r\n    @media only screen and (max-width: 991px) {\r\n        margin-left: 0;\r\n        width: 100%;\r\n        margin-top: 150px;\r\n    }\r\n    .iconSent {\r\n        color: white;\r\n    }\r\n    .iconError {\r\n        color: red;\r\n    }\r\n    p {\r\n        color: white;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `Sr2hLmHznzRfPFox78Bf`,
	"iconSent": `SzqvXoU6yWUZJKo1LxPl`,
	"iconError": `bhlUeRrXKAc9zuCiKA1W`
};
export default ___CSS_LOADER_EXPORT___;
